/**
 * useLogin.js
 * Handle PKCE Login Flow and Set Appropriate Variales
 */

import Vue from 'vue'
import  VueCompositionApi, { ref, computed } from '@vue/composition-api'
import { TOKEN_STORAGE_KEY, TOKEN_STORAGE_EXPIRES_ON, VERIFIER_STORAGE_KEY, REFRESH_STORAGE_KEY, RETURN_PATH_STORAGE_KEY } from '../utils/constants'
import { urlsafebtoa, abtobs, bstoab} from '../utils'

Vue.use(VueCompositionApi)

const {sign_in_url, token_exchange_url, token_exchange_post_params } = window.PKCE_CONFIG
const isAuthenticating = ref(true)
const loginToken = ref(null)
const loginState = ref(null)
const userInfo = ref(null)
let checkedStale = false

export default function useLogin() {
  const loggedIn = computed(_ => !!loginToken.value)
  const isActive = computed(() => loggedIn.value && loginState.value === 'active')

  async function login() {
    const code = crypto.getRandomValues(new Uint8Array(32))
    const code_verifier = urlsafebtoa(await abtobs(code))
    const bstoabCodeVerifier = await bstoab(code_verifier)
    const hash = await crypto.subtle.digest('SHA-256', bstoabCodeVerifier);
    const code_challenge = urlsafebtoa(await abtobs(hash))
    localStorage.setItem(VERIFIER_STORAGE_KEY, code_verifier)
    localStorage.setItem(RETURN_PATH_STORAGE_KEY, window.location.href)
    window.open(`${sign_in_url}&code_challenge=${code_challenge}`, '_self')
  }

  async function logout() {
    loginToken.value = null
    userInfo.value = null
    localStorage.removeItem(TOKEN_STORAGE_KEY)
    localStorage.removeItem(TOKEN_STORAGE_EXPIRES_ON)
    localStorage.removeItem(VERIFIER_STORAGE_KEY)
    localStorage.removeItem(REFRESH_STORAGE_KEY)
  }

  async function tokenExchange(user_data) {
    const body = new URLSearchParams(user_data).toString()
    return fetch(token_exchange_url, { method: 'POST', headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, body })
      .then(response => response.json())
      .then(response => setTokens(response))
      .then(_ => window.history.replaceState({}, document.title, window.location.origin + window.location.pathname)) // clean this up
  }

  async function setTokens({ access_token, refresh_token, expires_on }) {
    if(access_token) localStorage.setItem(TOKEN_STORAGE_KEY, access_token)
    if(refresh_token) localStorage.setItem(REFRESH_STORAGE_KEY, refresh_token)
    if(expires_on) localStorage.setItem(TOKEN_STORAGE_EXPIRES_ON, expires_on)
    if(access_token) loginToken.value = access_token
  }

  async function initAuth() {
    const access_token = localStorage.getItem(TOKEN_STORAGE_KEY)
    if (access_token) return verify(access_token)
    const code = new URLSearchParams(window.location.search).get('code')
    if (code) return validateLogin(code)
    isAuthenticating.value = false
  }
  
  async function validateLogin(code) {
    const code_verifier = localStorage.getItem(VERIFIER_STORAGE_KEY)
    let user_data = { grant_type: 'authorization_code', code_verifier, code }
    if(token_exchange_post_params) user_data = Object.assign(user_data, token_exchange_post_params)
    await tokenExchange(user_data);
    redirectAfterLogin();
  }

  async function redirectAfterLogin() {
    // Only redirect if on OIDC page
    const path = window.location.pathname;
    if (path !== '/oidc') return;
    const return_path = localStorage.getItem(RETURN_PATH_STORAGE_KEY);
    localStorage.removeItem(RETURN_PATH_STORAGE_KEY)
    if (typeof return_path !== 'undefined') {
        window.location.replace(return_path);
    } else {
        window.location.replace('//' + window.location.hostname);
    }
  }

  async function refreshExchange() {
    if(checkedStale) return resolve({ state: 'unknown' })
    checkedStale = true
    const refreshToken = localStorage.getItem(REFRESH_STORAGE_KEY)
    if (refreshToken) {
      let user_data = { grant_type: 'refresh_token', refresh_token: refreshToken }
      if(token_exchange_post_params) user_data = Object.assign(user_data, token_exchange_post_params)
      tokenExchange(user_data)
      const access_token = localStorage.getItem(TOKEN_STORAGE_KEY)
      if (access_token) return verify(access_token)
    }
  }

  async function verify(access_token) {
    const tokenExpiresOn = localStorage.getItem(TOKEN_STORAGE_EXPIRES_ON);
    loginState.value = 'inactive'
    if (!tokenExpiresOn) resolve({ state: 'unknown' })
    if(access_token) {
      const currentDate = new Date()
      const tokenDateExpiresOn = new Date(tokenExpiresOn * 1000)
      if (tokenDateExpiresOn >= currentDate) {
        loginToken.value = access_token
        isAuthenticating.value = true
        loginState.value = 'active'
        setUserData(access_token)
      } else resolve({ state: 'stale' })
    } else resolve({ state: 'unknown' })
  }

  async function resolve({ state }) {
    if(state === 'stale') return refreshExchange()
    if(state === 'unknown') return logout()
  }

  async function getUserData(access_token) {

    var apiUrl = "https://api.connect.rightworks.com/cpapa/user-service/v1/token";

    const currentDomain = window.location.hostname;
    if (currentDomain.includes('stage-cpapracticeadvisorsite')) {
      var apiUrl = "https://dev.api.connect.rightworks.com/cpapa/user-service/v1/token";
    }

      return await fetch(apiUrl, {
          method: "GET",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
  }

  async function setUserData(access_token) {
    if(access_token) {
       var userData = await getUserData(access_token)
       if (userData) {
        localStorage.setItem('user_data', JSON.stringify(userData));
        document.dispatchEvent(new Event("userDataLoaded"));
       }
    }
  }

  return { loggedIn, loginToken, isAuthenticating, isActive, loginState, userInfo, login, logout, initAuth }
}
