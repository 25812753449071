export const urlsafebtoa = (bs) => {
  var b64 = btoa(bs)
  b64 = b64.replaceAll('+', '-')
  b64 = b64.replaceAll('/', '_')
  b64 = b64.replaceAll('=', '')
  return b64
}

export const abtobs = (arraybuf) => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.onload = function(_e) { return resolve(reader.result) }
  reader.onabort = function() { return reject('aborted') }
  reader.onerror = function(_e) { return reject(reader.error) }
  reader.readAsBinaryString(new Blob([arraybuf]))
})

export const bstoab = (bs) => new Promise(function(resolve, reject) {
  const reader = new FileReader()
  reader.onload = function(_e) { return resolve(reader.result) }
  reader.onabort = function() { return reject('aborted') }
  reader.onerror = function(_e) { return reject(reader.error) }
  reader.readAsArrayBuffer(new Blob([bs]))
})

export const parseVerify = (str) =>  {
  const domParser = new window.DOMParser().parseFromString(str, "text/xml")
  const doc = domParser.documentElement
  const state = doc.getAttribute('state')
  const categories = doc.querySelectorAll('userinfo category')
  return [...categories].reduce((total, curr) => {
    total[curr.getAttribute('scheme')] = curr.getAttribute('term')
    return total
  }, { state })
}

export const dispatchEvent = (name, data) => {
  const event = new CustomEvent(name, { detail: { data }})
  window.dispatchEvent(event)
}

export const docCookies = {
  getItem: function (sKey) {
    if (!sKey || !this.hasItem(sKey)) { return null; }
    return unescape(document.cookie.replace(new RegExp("(?:^|.*;\\s*)" + escape(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*((?:[^;](?!;))*[^;]?).*"), "$1"));
  },
  setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
    if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return; }
    var sExpires = "";
    if (vEnd) {
      switch (vEnd.constructor) {
        case Number:
          sExpires = vEnd === Infinity ? "; expires=Tue, 19 Jan 2038 03:14:07 GMT" : "; max-age=" + vEnd;
          break;
        case String:
          sExpires = "; expires=" + vEnd;
          break;
        case Date:
          sExpires = "; expires=" + vEnd.toGMTString();
          break;
      }
    }
    document.cookie = escape(sKey) + "=" + escape(sValue) + sExpires + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "") + (bSecure ? "; secure" : "");
  },
  removeItem: function (sKey, sPath) {
    if (!sKey || !this.hasItem(sKey)) { return; }
    document.cookie = escape(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (sPath ? "; path=" + sPath : "");
  },
  hasItem: function (sKey) {
    return (new RegExp("(?:^|;\\s*)" + escape(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
  },
}

export const fetchPageAccess = () => {
  const metaNode = document.querySelector("meta[property='pugpig:access']")
  if (!metaNode) return 'paid'
  return metaNode.getAttribute('content')
}

export const fetchMeterCookie = (cookieName, cookieExpire) => {
  if(docCookies.getItem(cookieName)) return JSON.parse(docCookies.getItem(cookieName))
  const date = new Date();
  date.setTime(date.getTime() + (parseInt(cookieExpire) *1000))
  return { siteUrls:[], expireDate: date }
}