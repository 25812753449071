/**
 * useMeter.js
 * Handle Meter Access and Cookie Data.
 */

import Vue from 'vue'
import VueCompositionApi, { ref, computed, watchEffect } from '@vue/composition-api'
import { docCookies, fetchMeterCookie, fetchPageAccess } from '../utils'
import useLogin from './useLogin'

Vue.use(VueCompositionApi)

const { isAuthenticating, isActive } = useLogin()
const { meter, cookie_name, meter_expiry } = window.PKCE_CONFIG
const pageAccess = ref(fetchPageAccess())
const meterVisible = ref(false)
const meterAccess = ref(false)
const cookieData = ref(fetchMeterCookie(cookie_name, meter_expiry))
const meterMax = ref(parseInt(meter))

export default function useMeter() {
  const meterCount = computed(_ => cookieData.value.siteUrls.length)

  watchEffect(setMeterCookie)
  watchEffect(setMeterAccess)

  function setMeterCookie() {
    const date = new Date(cookieData.value.expireDate)
    docCookies.setItem(cookie_name, JSON.stringify(cookieData.value), date, "/");
  }

  function setMeterAccess() {
    if(isAuthenticating.value) return
    if(pageAccess.value !== 'paid' || isActive.value || meterMax.value <= 0) return meterVisible.value = meterAccess.value = false
    const inMeter = cookieData.value.siteUrls.length < meter
    const urlVisited = cookieData.value.siteUrls.includes(window.location.href)
    if(!inMeter && !urlVisited) return meterVisible.value = true
    if(!cookieData.value.siteUrls.includes(window.location.href)) cookieData.value.siteUrls.push(window.location.href)
    meterVisible.value = meterAccess.value = true
  }

  return { meterVisible, meterAccess, cookieData, meterCount, meterMax }
}