import { watch, watchEffect } from '@vue/composition-api'
import { useLogin, useMeter } from './composables'
import { createElement } from './components/Paywall'

const { loggedIn, loginState, userInfo, isActive, initAuth, login, logout } = useLogin()
const { meterVisible, meterAccess, meterCount, meterMax } = useMeter()

/**
 * Watch the various values and fire off dom events and add classes.
 */
watchEffect(() => {
  document.body.dataset.loggedIn = loggedIn.value
  document.body.dataset.loggedOut = !loggedIn.value
  document.body.dataset.hasAccess = isActive.value || meterAccess.value
  document.body.dataset.unknownAccess = !isActive.value && !meterAccess.value
  document.body.dataset.meterVisible = meterVisible.value
  document.body.dataset.meterCount = meterCount.value
  document.body.dataset.meterMax = meterMax.value
})

watchEffect(_ => {
  if(!loginState.value) return // stop null being passed (if needed we can default it to inactive)
  window.dataLayer = window.dataLayer || []
  dataLayer.push({'pugpigAuthStatus': loginState.value })
  dataLayer.push({event: 'auth_status_updated'})
})

watch(_ => userInfo.value, (newValue, oldValue) => {
  // If oldValue is set that means it isn't the first run, remove this line or use watchEffect for constant checking..
  if(oldValue) return 
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({'pugpig_user': newValue})
  window.dataLayer.push({ event: 'pugpig_user_updated' })
  const event = new CustomEvent("PugpigUserLoaded", { detail: { user: newValue }})
  window.dispatchEvent(event)
})

/**
 * Debug to show that things are changin' and events work.
 * This can be removed once we're happy
 */

watchEffect(_ => {
  window._pp_ = window._pp_ || {}
  window._pp_.auth = {
    loggedIn: loggedIn.value, 
    isAuthorised: isActive.value || meterAccess.value, 
    userInfo: userInfo.value,
    meterVisible: meterVisible.value,
    meterCount: meterCount.value,
    meterMax: meterMax.value,
  }
})

/**
 * Mount Apps if they exist and event listener.
 * Dynamically request js file if page exists.
 */

window.addEventListener('PugpigUserLoaded', _ => {
  console.log('PugpigUserLoaded::', window.dataLayer || [])
})

window.addEventListener('DOMContentLoaded', () => {
  // Bind Event Listeners for Buttons
  const login_buttons = document.querySelectorAll('#pp-subs-login')
  const logout_buttons = document.querySelectorAll('#pp-subs-logout')
  const subscribe_buttons = document.querySelectorAll('.cpa-button__subscribe')
  login_buttons.forEach(button => button.addEventListener('click', login))
  subscribe_buttons.forEach(button => button.addEventListener('click', login))
  logout_buttons.forEach(button => button.addEventListener('click', logout))
  // Initialise Auth
  initAuth()
})

window.addEventListener('DOMContentLoaded', _ => {
  const nodes = document.querySelectorAll('.pkce-app');
  (nodes && nodes.length > 0) && createElement(nodes)
})