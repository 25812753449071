import { createApp } from '@vue/composition-api'
import { useLogin, useMeter } from '../composables'

const meterData = useMeter()
const loginData = useLogin()

export const Paywall = (template) =>  ({
  template: template,
  setup(_, ctx) {
    const config = window.PKCE_CONFIG
    
    function cls(method, className, selector) {
      const el = selector ? document.querySelector(selector) : ctx.root.$el
      el.classList[method](className)
    }

    return { cls, ...meterData, ...loginData, config }
  }
})

export const createElement = (nodes) => {
  [...nodes].forEach(node => {
    const component = Paywall(node.dataset.template || node.innerHTML)
    createApp(component).mount(node)
  })
}